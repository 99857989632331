import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChartBar } from 'react-icons/fa';
import Slider from 'react-slick';
import sanityClient from '../sanityClient';
import { urlFor } from '../imageBuilder';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MarketInsightsCarousel = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Detect small screen

  const [carouselKey, setCarouselKey] = useState(0); // Force re-render of Slider

  const sliderRef = useRef(null); // Reference to the Slider component

  // Detect screen size changes
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Define breakpoint for small screens
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch the latest articles from Sanity
    sanityClient
      .fetch(
        `*[_type == "article"] | order(publishedAt desc)[0...10]{
          _createdAt,
          name,
          author->{name},
          slug,
          banner,
          synopsis,
          categoryType,
          articleType,
          publishedAt
        }`
      )
      .then((data) => {
        setArticles(data);
        setFilteredArticles(data);
        const uniqueCategories = ['All', ...new Set(data.map((article) => article.categoryType))];
        setCategoryTypes(uniqueCategories);
      })
      .catch(console.error);
  }, []);

  // Filter articles based on category
  const filterByCategory = (category) => {
    setSelectedCategory(category);
    const newFilteredArticles = category === 'All' ? articles : articles.filter((article) => article.categoryType === category);
    setFilteredArticles(newFilteredArticles);

    // Reset the slider when filtering happens
    setCarouselKey((prevKey) => prevKey + 1); // Force re-render with a new key
  };

  // Carousel settings
  const settings = {
    key: carouselKey, // Force re-render by updating the key
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Ensure one article per slide on medium and small screens
    slidesToScroll: 1,
    centerMode: isSmallScreen ? false : true, // Disable center mode for small screens
    centerPadding: isSmallScreen ? '0px' : '40px', // No padding on small screens, regular padding on larger screens
    responsive: [
      {
        breakpoint: 1024, // Medium screens (1 article per slide)
        settings: {
          slidesToShow: 1, // Show one article at a time
          slidesToScroll: 1,
          centerMode: false, // No centering on medium screens
          centerPadding: '0px', // Ensure full width usage for the article
        },
      },
      {
        breakpoint: 768, // Small screens
        settings: {
          slidesToShow: 1, // Show one slide at a time on small screens
          slidesToScroll: 1,
          centerMode: false, // No centering for small screens
          centerPadding: '0px', // Full width on small screens
        },
      },
    ],
  };

  return (
    <div className="w-full md:w-4/5 mx-auto py-8"> {/* Use full width on small screens */}
      {/* Adjust the grid layout with responsive classes */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Articles Column */}
        <div className="md:col-span-3">
          <h2 className="text-2xl font-bold mb-6">Latest Articles</h2>

          {/* Category Filter */}
          <div className="flex items-center mb-6">
            <h3 className="text-sm font-normal mr-4">Filter by:</h3>
            {isSmallScreen ? (
              // Render dropdown for small screens
              <select
                value={selectedCategory}
                onChange={(e) => filterByCategory(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg"
              >
                {categoryTypes.map((category, idx) => (
                  <option key={idx} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            ) : (
              // Render pills for larger screens
              <div className="flex space-x-2">
                {categoryTypes.map((category, idx) => (
                  <div
                    key={idx}
                    className={`px-2 py-1 rounded-full cursor-pointer border text-sm ${
                      selectedCategory === category ? 'bg-blue-700 text-white' : 'bg-white text-gray-800 border-gray-300'
                    }`}
                    onClick={() => filterByCategory(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Articles Carousel */}
          <Slider {...settings} ref={sliderRef}>
            {filteredArticles.map((article, idx) => (
              <div key={`${article.slug.current}-${idx}`} className="p-4">
                <div className="relative">
                  <div className="absolute top-2 left-2 bg-white text-sm font-bold px-3 py-1 rounded-md shadow z-10">
                    {new Date(article.publishedAt || article._createdAt).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </div>

                  <img
                    src={urlFor(article.banner).width(800).url()}
                    alt={article.name}
                    className="w-full h-48 object-cover rounded-lg"
                  />

                  <div className="px-4 pt-4 pb-0">
                    <div className="flex items-center mb-1">
                      <div className="bg-orange-100 text-orange-900 text-xs font-bold px-2 py-1 rounded-full mr-2">
                        {article.categoryType}
                      </div>
                      <p className="text-sm text-gray-700">Author: {article.author.name}</p>
                    </div>

                    <Link to={`/market-insights/${article.slug.current}`}>
                      <h3 className="text-lg font-semibold hover:underline mt-2 mb-1">{article.name}</h3>
                    </Link>

                    <p className="text-sm mt-2">{article.synopsis}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Reports Section */}
        <div className="md:col-span-1">
          <div className="flex items-center mb-4">
            <FaChartBar className="text-green-500 text-lg mr-2" />
            <h3 className="text-lg font-bold">Reports</h3>
          </div>

          <div className="bg-gray-100 p-2 shadow-md">
            <ul className="space-y-4">
              <li className="bg-white p-3 shadow">
                <span className="text-md font-bold">AWEX weekly market indicator</span>
              </li>
              <li className="bg-white p-3 shadow">
                <span className="text-md font-bold">Trade lamb indicator</span>
              </li>
              <li className="bg-white p-3 shadow">
                <span className="text-md font-bold">National young cattle indicator</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketInsightsCarousel;
