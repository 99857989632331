import React from 'react';

const Home = () => {
  return (
    <div className="p-8">
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gray-300 p-4">Tile 1</div>
        <div className="bg-gray-300 p-4">Tile 2</div>
        <div className="bg-gray-300 p-4">Tile 3</div>
      </div>
    </div>
  );
};

export default Home;
