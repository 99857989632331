import React, { useState, useEffect } from 'react';

const Modal = ({ imageUrl, onClose }) => {
  const [isVisible, setIsVisible] = useState(false); // Control the transition visibility state

  // Handle showing the modal with a slight delay to allow transition
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 75); // Delay for transition effect
  }, []);

  // Handle closing the modal with a smooth fade-out effect
  const handleClose = () => {
    setIsVisible(false); // Start transition
    setTimeout(() => {
      onClose(); // After transition ends, close modal
    }, 400); // Match the transition duration
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose(); // Trigger close when background is clicked
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 transition-opacity duration-400 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleBackgroundClick}
    >
      <div
        className={`relative transition-transform duration-400 transform ${
          isVisible ? 'scale-100' : 'scale-90'
        }`}
      >
        {/* Full-size image */}
        <img src={imageUrl} alt="Full Size" className="max-w-full max-h-full" />
      </div>
    </div>
  );
};

export default Modal;
