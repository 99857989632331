import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCuKSV3BFDjS-yItcyWCstI_6no00TPbtQ",
    authDomain: "insights-poc-8be4e.firebaseapp.com",
    projectId: "insights-poc-8be4e",
    storageBucket: "insights-poc-8be4e.appspot.com",
    messagingSenderId: "341984281448",
    appId: "1:341984281448:web:bd77722a2518633fba8cdd"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Messaging
const messaging = getMessaging(app);

// Function to request push notification permissions
export const requestPermission = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: 'BFfLZgaI0EB0FTH8KzGFLLVk1tp1V2lXeOBWMIx8leWZZL3q6NQvRRjCR61HEHxryxB9hHP6eh5l7LkWBxqvVik' });

    if (token) {
      console.log('FCM Token:', token);
      alert("token: "+token);
      // Send this token to your server to send push notifications
    } else {
      console.log('No registration token available.');
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
  }
};

// Retrieve the FCM token
export const requestFCMToken = async () => {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: 'BFfLZgaI0EB0FTH8KzGFLLVk1tp1V2lXeOBWMIx8leWZZL3q6NQvRRjCR61HEHxryxB9hHP6eh5l7LkWBxqvVik', // Add your web push VAPID key here
      });
  
      if (currentToken) {
        console.log('FCM token retrieved:', currentToken);
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
        return null;
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
      return null;
    }
  };

// Function to handle foreground messages
onMessage(messaging, (payload) => {
  console.log('Message received in foreground: ', payload);
  alert(`Notification: ${payload.notification.title}`);
});
