import React, { useEffect, useState } from 'react';
import { requestFCMToken } from '../firebase'; // Make sure this points to where you defined your Firebase logic

const Footer = () => {

  const [fcmToken, setFcmToken] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      const token = await requestFCMToken();
      setFcmToken(token || 'No token available');
    };

    fetchToken();
  }, []);

  return (
    <footer className="w-full bg-gray-100 border-t border-gray-300 py-4">
      <div className="w-4/5 mx-auto text-center">
        <p className="text-sm text-gray-500">&copy; 2024 Market Insights. All rights reserved.</p>
        <p className="text-sm text-gray-500">FCM Token: {fcmToken}</p>
      </div>
    </footer>
  );
};

export default Footer;
