import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // To store the search query

  useEffect(() => {
    // Fetch FAQs from Sanity
    sanityClient
      .fetch(`*[_type == "faq"]{question, answer}`)
      .then((data) => {
        setFaqs(data);
        setExpandedIndices(new Array(data.length).fill(false)); // Initialize all FAQs as collapsed
      })
      .catch(console.error);
  }, []);

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    setExpandedIndices((prevIndices) =>
      prevIndices.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
    );
  };

  // Function to filter FAQs based on the search query
  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-full md:w-4/5 mx-auto py-8 px-4 md:px-0"> {/* Responsive container */}
      <h2 className="text-2xl md:text-3xl font-bold mb-6">Frequently Asked Questions</h2>

      {/* Search Filter Input */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search FAQs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update the search query state
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      {/* FAQ List */}
      <div className="space-y-4">
        {filteredFaqs.length > 0 ? (
          filteredFaqs.map((faq, index) => (
            <div key={index} className="border border-gray-300 rounded-lg shadow">
              {/* FAQ Question */}
              <div
                onClick={() => toggleAccordion(index)}
                className="cursor-pointer bg-gray-100 p-4 text-lg font-medium flex justify-between items-center"
              >
                <span>{faq.question}</span>
                <span>{expandedIndices[index] ? '-' : '+'}</span> {/* Toggle icon */}
              </div>

              {/* FAQ Answer (Collapsible Section) */}
              {expandedIndices[index] && (
                <div className="p-4 text-gray-700 bg-white">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No FAQs found matching your search query.</p>
        )}
      </div>
    </div>
  );
};

export default FAQs;
