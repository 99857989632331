import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import Splash from './pages/Splash';
import Home from './pages/Home';
import MarketInsights from './pages/MarketInsights';
import MarketInsightsCarousel from './pages/MarketInsightsCarousel';
import MarketInsightsArticle from './pages/MarketInsightsArticle';
import FAQs from './pages/FAQs'; // Import the new FAQs page
import PrivateRoute from './components/PrivateRoute';
import { requestPermission } from './firebase'; // Import your Firebase setup

function App() {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    document.title = 'Market Insights';

    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = '/favicon.ico'; // Ensure the favicon is placed in the public folder
    }
  }, []);

  useEffect(() => {
    // Request permission when the component mounts
    requestPermission();
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          {/* Splash page route */}
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/home" /> : <Splash />}
          />

          {/* Protected routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/market-insights" element={<MarketInsights />} />
            <Route path="/market-insights-carousel" element={<MarketInsightsCarousel />} />
            <Route path="/market-insights/:slug" element={<MarketInsightsArticle />} />
            <Route path="/faqs" element={<FAQs />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
