import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Splash = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-200 text-gray-900 px-4">
      {/* Lighter blue background with padding on small screens */}
      <div className="text-center space-y-6 max-w-md mx-auto"> 
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Welcome to Market Insights
        </h1>
        <p className="text-lg md:text-xl">
          Access the latest insights on the market, tailored just for you.
        </p>
        <p className="text-sm md:text-md italic">
          Sign in to get started and stay updated with the latest market trends.
        </p>

        {/* Call to action - Blue Button */}
        <button
          onClick={() => loginWithRedirect()}
          className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg text-lg"
        >
          Login / Signup
        </button>
      </div>
    </div>
  );
};

export default Splash;
