import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for hamburger and close
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0 hook

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0(); // Get login and logout functions from Auth0

  // Toggle the menu state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Header for medium and large screens */}
      <header className="w-full border-b border-gray-300 hidden md:block">
        <div className="w-4/5 mx-auto flex items-center justify-between py-5">
          {/* Fancy 'I' logo */}
          <div className="flex items-center">
            <div className="bg-black text-green-500 text-3xl font-bold p-3 rounded-full mr-4">I</div>
            <h1 className="text-3xl font-bold">Market Insights</h1>
          </div>
          
          {/* Conditionally render login or logout button */}
          {isAuthenticated ? (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </button>
          ) : (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold"
              onClick={() => loginWithRedirect()}
            >
              Login
            </button>
          )}
        </div>
      </header>

      {/* Flyout Menu for small screens */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50 md:hidden`}
      >
        <div className="flex justify-between items-center p-5 border-b border-gray-300">
          {/* Market Insights Title in the Menu */}
          <h2 className="text-xl font-bold">Market Insights</h2>
          <button onClick={toggleMenu} className="text-3xl">
            <FaTimes /> {/* Close icon when the menu is open */}
          </button>
        </div>
        <nav className="flex flex-col p-5 space-y-4">
          <Link to="/" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Home
          </Link>
          <Link to="/market-insights" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Market Insights
          </Link>
          <Link to="/market-insights-carousel" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Market Insights v2
          </Link>
          <Link to="/faqs" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Frequently Asked Questions
          </Link>

          {/* Conditionally render login or logout button in the mobile menu */}
          {isAuthenticated ? (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold"
              onClick={() => {
                toggleMenu();
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </button>
          ) : (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold"
              onClick={() => {
                toggleMenu();
                loginWithRedirect();
              }}
            >
              Login
            </button>
          )}
        </nav>
      </div>
    </>
  );
};

export default Header;
